@import './reset.css';
@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
    background-color: #303030;
    color: #FFFFFF;
}

#app {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
